<template>
  <div class="guides-table mt-4">
    <el-table
      v-loading="loading"
      :data="data ? data.data : []"
      style="width: 100%"
      :row-class-name="tableRowClassName"
    >
      <el-table-column label="№ заказа">
        <template slot-scope="scope"> # {{ scope.row.id }} </template>
      </el-table-column>
      <el-table-column prop="displayDate" label="Дата заказа" />
      <el-table-column prop="count" label="Количество блюд" />
      <el-table-column prop="sum" label="Сумма заказа" />
    </el-table>
  </div>
</template>

<script>
import { LOADING, DATA } from '@/app/clients/shared/state/types/getter_types';
import { mapActions, mapGetters } from 'vuex';
import { FETCH_ORDERS } from '@/app/clients/shared/state/types/action_types';
import { appLocalStorage } from '@/app/shared/services';

export default {
  name: 'HistoryTable',
  data() {
    return {
      fields: [
        { label: '#', value: 'id', width: 70 },
        { label: 'Название', value: 'dish.title', width: 300 },
        { label: 'Категория', value: 'dish.meal.title' },
        { label: 'Цена', value: 'price' },
        { label: 'Филиал', value: 'branch.title', width: 150 },
      ],
      tableRules: [
        {
          value: 'edit',
          type: 'primary',
          icon: 'el-icon-edit',
        },
        {
          value: 'delete',
          type: 'danger',
          icon: 'el-icon-delete',
        },
      ],
      modal: {
        show: false,
        title: null,
      },
      filter: appLocalStorage.getItem('filters').guide,
    };
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      data: DATA,
    }),
  },
  async mounted() {
    await this.fetch({ client: this.$route.params.id, mode: 'history' });
  },
  methods: {
    ...mapActions({ fetch: FETCH_ORDERS }),
    tableRowClassName({ row }) {
      if (row.current) {
        return 'current-order';
      }
      return '';
    },
    async reloadData(page) {
      if (this.filter.guide) {
        await this.fetch({ filter: this.filter.guide.value, page });
      } else {
        await this.fetch({ page });
      }
    },
    async handleEdit(guide) {
      await this.select({
        id: guide.id,
        dish_id: guide.dish.id,
        title: guide.dish.title,
        branch: guide.branch ? guide.branch.title : null,
        price: guide.price,
      });
      this.modal.show = true;
      this.modal.title = 'Редактирование блюда';
    },
    handleDelete(guide) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          await this.delete(guide.id);
          if (this.error) {
            this.showErrorMessage('Ошибка удаления');
          }
          this.showSuccessMessage('Блюдо успешно удален');
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.el-table .current-order {
  background: #f0f9eb;
}
</style>
