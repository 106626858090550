<template>
  <div class="history-order">
    <history-table />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HistoryTable from './history-table/history-table.vue';

export default {
  name: 'History',
  components: { HistoryTable },
  data() {
    return {
      loading: false,
      fields: [
        { label: 'Заказ #', value: 'order_num' },
        { label: 'Статус', value: 'status' },
        { label: 'Дата изменения', value: 'date_edit' },
      ],
    };
  },
  computed: {
    ...mapGetters({ histories: 'HISTORIES' }),
  },
  async mounted() {
    await this.$store.dispatch('FETCH_CLIENT', this.$route.params.id);
    await this.$store.dispatch('FETCH_HISTORIES');
  },
  methods: {
    handleOpen() {},
  },
};
</script>

<style lang="scss" scoped></style>
